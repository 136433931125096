import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';

// components and helpers
import newshashLogo from '../assets/images/newshash.svg';
import proofImage from '../assets/images/proofOfAuth.svg';
import { articleBuild } from '../components/Article/helpers/articleBuild';
import ReadArticleBuilder from '../components/Article/ReadArticleBuilder';
import ProveArticleBuilder from '../components/Article/ProveArticleBuilder';
import TitleSection from '../components/Article/Sections/TitleSection';
import { IArticle } from '../components/Article/type';
import Footer from '../components/Shared/Footer';

// mui
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

export interface SelectedProps {
    [key: string]: boolean;
}

export interface HomeViewProps {
    selected?: SelectedProps;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    index?: number;
    article?: IArticle;
}

export const HomeContext = React.createContext<HomeViewProps>({});
const HomeView: React.FC<HomeViewProps> = ({}) => {
    const [selected, setSelected] = useState<SelectedProps>({});
    const [run, setRun] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRun(true);
        }, 1000);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected({ ...selected, [event.target.name]: event.target.checked });
    };

    return (
        <Grid container style={{ marginTop: 30 }} justify={'center'}>
            <Joyride
                showSkipButton={true}
                steps={[
                    {
                        content: (
                            <div>
                                Read about our proof of concept, providing a blockchain solution to the fight against
                                fake news.{' '}
                            </div>
                        ),
                        placement: 'auto',
                        disableBeacon: true,
                        disableOverlayClose: true,
                        hideCloseButton: false,
                        spotlightClicks: true,
                        styles: {
                            overlay: {
                                backgroundColor: 'none',
                            },
                            spotlight: {
                                backgroundColor: 'none',
                                borderRadius: 0,
                                boxShadow: '0px 0px 0px 9999px rgba(0, 0, 0, 0.5)',
                            },
                        },
                        target: '.introduction',
                        title: 'About NewsHash',
                    },
                    {
                        content: (
                            <div>
                                Click here to toggle between read mode to view the article and prove mode to verify text
                                and assets of the article.
                            </div>
                        ),
                        locale: { last: 'Close' },
                        // placement: 'auto',
                        disableBeacon: true,
                        disableOverlayClose: true,
                        hideCloseButton: false,
                        spotlightClicks: true,
                        styles: {
                            overlay: {
                                backgroundColor: 'none',
                            },
                            spotlight: {
                                backgroundColor: 'none',
                                borderRadius: 0,
                                boxShadow: '0px 0px 0px 9999px rgba(0, 0, 0, 0.5)',
                            },
                        },
                        target: '.proofSwitch',
                        title: 'Navigate to Prove Mode',
                        floaterProps: {
                            disableAnimation: true,
                        },
                    },
                ]}
                run={run}
                disableScrolling={false}
                continuous={true}
            />
            <Grid item xs={11} md={7}>
                <Grid container justify={'center'}>
                    <Grid item>
                        <img src={newshashLogo} alt="NewHash Logo" style={{ width: '100%', marginBottom: 30 }} />
                    </Grid>
                    <Grid item>
                        <img src={proofImage} alt="NewHash Logo" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container justify={'center'}>
                    <Grid item>
                        <Paper
                            className={'introduction'}
                            style={{ padding: 20, backgroundColor: '#ECF0F5', margin: '50px 0px', borderRadius: 25 }}
                        >
                            <Typography variant={'subtitle1'} color={'primary'}>
                                Welcome to NewsHash, an experimentation developed by the company ACOER to illustrate how
                                a blockchain solution could be used in the fight against fake news. Every item on this
                                page, including the article, the video, the video transcript and the illustration, have
                                been timestamped and a fingerprint of each asset has been recorded on an immutable
                                ledger called{' '}
                                <a
                                    href="https://hedera.com/"
                                    target={'_blank'}
                                    rel="noreferrer"
                                    style={{ textDecoration: 'underline dotted', color: 'gray' }}
                                >
                                    Hedera Hashgraph
                                </a>
                                . The &quot;read mode&quot; of this page is no different than a regular news page, but
                                if you enable the &quot;prove mode&quot;, you&apos;ll be able to verify, and see the
                                stamps of every asset. Each stamp is represented as a QR code that you can scan and
                                you&apos;ll be redirected to ACOER Scanner Page to show you the content of the stamp.
                                This proof of concept is part of a collaboration work between{' '}
                                <a
                                    href="https://www.acoer.com/"
                                    target={'_blank'}
                                    rel="noreferrer"
                                    style={{ textDecoration: 'underline dotted', color: 'gray' }}
                                >
                                    Acoer
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://forkast.news/"
                                    target={'_blank'}
                                    rel="noreferrer"
                                    style={{ textDecoration: 'underline dotted', color: 'gray' }}
                                >
                                    Forkast.News
                                </a>
                                .
                            </Typography>
                        </Paper>
                    </Grid>
                    {articleBuild.map((article, index) => {
                        return (
                            <Grid container key={`article-${index}`}>
                                <Grid item xs={11}>
                                    <HomeContext.Provider value={{ selected, handleChange, index }}>
                                        <TitleSection section={article} />
                                    </HomeContext.Provider>
                                </Grid>
                                {selected[`switch-${index}`] ? (
                                    <HomeContext.Provider value={{ article }}>
                                        <ProveArticleBuilder helper={article.articleProps} />
                                    </HomeContext.Provider>
                                ) : (
                                    <ReadArticleBuilder helper={article.articleProps} />
                                )}
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default HomeView;
