import removeMd from 'remove-markdown';

export const getMarkdownText = async (value) => {
    const file = await import(`../markdowns/${value}.md`);
    const response = await fetch(file.default);
    const text = await response.text();
    const cleanText = removeMd(text)
        .replace(/\n{2,}/g, '\n')
        .replace(/\n$/, '');
    return {
        text,
        cleanText,
        count: cleanText.length,
    };
};
