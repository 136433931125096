import React from 'react';
import ResponsiveEmbed from 'react-responsive-embed';

// components and helpers
import { IArticleProps, IItemDetailsProps } from '../type';

// mui
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

export interface ProofCardDetailsProps {
    proofData: IItemDetailsProps | undefined;
    section: IArticleProps;
}

const ProofCardDetails: React.FC<ProofCardDetailsProps> = ({ proofData, section }) => {
    if (!proofData)
        return (
            <Grid container justify={'center'}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    const pubData = proofData.pub;
    return (
        <Grid item xs={12}>
            {pubData?.youtube && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <ResponsiveEmbed
                        src={`https://www.youtube.com/embed/${pubData?.youtube.split('/').pop()}`}
                        ratio="16:9"
                    />
                </Grid>
            )}
            {section.image && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <img src={section.image} alt={'image'} style={{ width: '100%' }} />
                </Grid>
            )}
            {pubData?.type && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Asset Type:</b> <i>{pubData?.type}</i>
                </Typography>
            )}
            {pubData?.title && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Title:</b> <i>{pubData?.title}</i>
                </Typography>
            )}
            {pubData?.visibleOn && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Initial Publish:</b>{' '}
                    <a
                        href={pubData?.visibleOn}
                        target={'_blank'}
                        rel="noreferrer"
                        style={{ textDecoration: 'underline dotted', color: 'gray' }}
                    >
                        <i>{pubData?.visibleOn}</i>
                    </a>
                </Typography>
            )}
            {pubData?.publishedOn && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Published:</b> <i>{pubData?.publishedOn}</i>
                </Typography>
            )}
            {pubData?.charsCount && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Number of Characters:</b> <i>{pubData?.charsCount}</i>
                </Typography>
            )}
            {pubData?.format && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Format:</b> <i>{pubData?.format}</i>
                </Typography>
            )}
            {pubData?.resolution && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Resolution:</b> <i>{pubData?.resolution}</i>
                </Typography>
            )}
            {pubData?.size && (
                <Typography variant={'subtitle2'} color="textSecondary">
                    <b>Size:</b> <i>{pubData?.size}</i>
                </Typography>
            )}
            {proofData?.hash && (
                <Typography variant={'subtitle2'} color="textSecondary" style={{ wordBreak: 'break-all' }}>
                    <b>Content Fingerprint:</b> <i>{proofData?.hash}</i>
                </Typography>
            )}
        </Grid>
    );
};

export default ProofCardDetails;
