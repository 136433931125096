import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IArticleProps } from '../type';

export interface ImageSectionProps {
    section: IArticleProps;
}

const ImageSection: React.FC<ImageSectionProps> = ({ section = {} }) => {
    return (
        <Grid container justify={'center'}>
            <img src={section.image} alt="" style={{ width: '100%' }} />
        </Grid>
    );
};

export default ImageSection;
