import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import ProofCountdown from './ProofCountdown';
import moment from 'moment';

// mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        qrCodeImg: {
            width: '100%',
            '&:hover': {
                cursor: 'nwse-resize',
            },
        },
    }),
);

export interface ProofVerifyDetailsProps {
    qrCode: string | undefined;
    stampedOn: string | undefined;
    transactionId: string | undefined;
    tokenExpiration: string | undefined;
    countdownCount: number | undefined;
    countdown: boolean;
    handleVerify: () => void;
}

const ProofVerifyDetails: React.FC<ProofVerifyDetailsProps> = ({
    qrCode,
    stampedOn,
    transactionId,
    tokenExpiration,
    countdownCount,
    countdown = false,
    handleVerify,
}) => {
    type GRID_SIZES = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    const [imageZoom, setImageZoom] = useState<GRID_SIZES>(3);
    const classes = useStyles();

    const handleZoomImage = () => {
        if (imageZoom === 3) {
            setImageZoom(12);
        } else {
            setImageZoom(3);
        }
    };

    return (
        <Grid container justify={'space-between'}>
            <Grid item xs={8}>
                <Typography variant={'h6'} color={'textSecondary'} style={{ fontWeight: 'bold' }}>
                    Content Verified
                </Typography>
                <Typography variant={'subtitle2'} color={'textSecondary'}>
                    <b>Stamped on:</b>
                    <br />
                    <i>{moment.utc(stampedOn).format('MM/DD/YYYY')}</i>
                </Typography>
                <Typography variant={'subtitle2'} color={'textSecondary'}>
                    <b>Transaction ID:</b>
                    <br />
                    <Tooltip title={'View tx in HashLog Ledger'}>
                        <a
                            href={`https://ledger.hashlog.io/tx/${transactionId}`}
                            target={'_blank'}
                            rel="noreferrer"
                            style={{ textDecoration: 'underline dotted', color: 'gray' }}
                        >
                            <i>{transactionId}</i>
                        </a>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={imageZoom as GRID_SIZES}>
                {qrCode && qrCode.includes('blob') ? (
                    <Tooltip
                        title={imageZoom === 3 ? 'Click to enlarge QR code for easier scan' : 'Click to shrink QR code'}
                    >
                        <img
                            src={qrCode}
                            alt="verified-qr-code"
                            className={classes.qrCodeImg}
                            onClick={handleZoomImage}
                        />
                    </Tooltip>
                ) : (
                    <QRCode value={qrCode} style={{ width: '100%', height: 'auto' }} />
                )}
            </Grid>
            {countdown && (
                <Grid container justify={'center'} style={{ marginTop: 10 }}>
                    <Grid item>
                        <ProofCountdown
                            tokenExpiration={tokenExpiration}
                            countdownCount={countdownCount}
                            handleVerify={handleVerify}
                            allowRetry={true}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ProofVerifyDetails;
