import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { IArticleProps } from '../type';
import Markdown from 'markdown-to-jsx';
import { getMarkdownText } from '../helpers/utils';
import { Typography } from '@material-ui/core';

export interface TextSectionProps {
    section: IArticleProps;
}

const TextSection: React.FC<TextSectionProps> = ({ section = {} }) => {
    const [md, setMd] = useState('');
    useEffect(() => {
        async function getText() {
            const markdownText = await getMarkdownText(section.markdown);
            setMd(markdownText.text);
        }

        if (section.markdown) {
            getText();
        }
    }, []);

    return (
        <Grid container justify={'center'}>
            <Grid item>
                <Markdown>{md}</Markdown>
            </Grid>
            {section.authorSign && (
                <Grid item xs={12}>
                    <Typography variant={'body2'} style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                        By {section.authorSign}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default TextSection;
