import React from 'react';
import Grid from '@material-ui/core/Grid';
import VideoSection from './Sections/VideoSection';
import ImageSection from './Sections/ImageSection';
import TextSection from './Sections/TextSection';
import { IArticleProps } from './type';

export interface ArticleBuilderProps {
    helper: IArticleProps[];
}

const ReadArticleBuilder: React.FC<ArticleBuilderProps> = ({ helper = [] }) => {
    const articleSections: any = helper.map((section, i) => {
        return (
            <React.Fragment key={`section-${i}`}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    {section.type === 'article' && section.readRender && <TextSection section={section} />}
                    {section.type === 'video' && section.readRender && <VideoSection section={section} />}
                    {section.type === 'illustration' && section.readRender && <ImageSection section={section} />}
                </Grid>
            </React.Fragment>
        );
    });
    return articleSections;
};

export default ReadArticleBuilder;
