import React, { useEffect, useState } from 'react';

// components and helpers
import { IArticleProps } from './type';
import ProofCard from './Sections/ProofCard';

// mui
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

export interface ArticleBuilderProps {
    helper: IArticleProps[];
}

const ProveArticleBuilder: React.FC<ArticleBuilderProps> = ({ helper = [] }) => {
    const [leftColumns, setLeftColumns] = useState<IArticleProps[]>([]);
    const [rightColumns, setRightColumns] = useState<IArticleProps[]>([]);

    useEffect(() => {
        createColumns(helper);
    }, []);

    const createColumns = (helper: any) => {
        const left: IArticleProps[] = [];
        const right: IArticleProps[] = [];
        const assets = ['video', 'illustration'];
        const text = ['article', 'video-transcript'];
        helper.map((section: any, i: number) => {
            if (text.includes(section.queryItem)) {
                left.push(<ProofCard title={section.title} section={section} key={`card-${i}`} />);
            }
            if (assets.includes(section.type)) {
                right.push(<ProofCard title={section.title} section={section} key={`card-${i}`} />);
            }
        });
        setLeftColumns(left);
        setRightColumns(right);
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                style={{ margin: '50px 0' }}
                justify={'space-around'}
                alignContent={'center'}
            >
                <Grid item xs={12} md={5}>
                    <Grid container direction="column">
                        {leftColumns.map((section, index) => {
                            return (
                                <Grid item xs={12} key={`leftColumn-${index}`} style={{ marginTop: 30 }}>
                                    {section}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider orientation={'vertical'} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container direction="column">
                        {rightColumns.map((section, index) => {
                            return (
                                <Grid item xs={12} key={`rightColumn-${index}`} style={{ marginTop: 30 }}>
                                    {section}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ProveArticleBuilder;
