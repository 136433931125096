import * as actionTypes from './actionTypes';
import { ArticleAction, ArticleState } from '../type';

const initialState: ArticleState = {
    items: {},
    proofs: [],
    scan: {},
};

const reducer = (state: ArticleState = initialState, action: ArticleAction): ArticleState => {
    switch (action.type) {
        case actionTypes.ITEM_DETAIL_REQUEST:
            return <ArticleState>{
                ...state,
                items: {
                    ...state.items,
                    [action.articleId as string]: [],
                },
            };
        case actionTypes.ITEM_DETAIL_SUCCESS:
            return <ArticleState>{
                ...state,
                items: {
                    ...state.items,
                    [action.articleId as string]: [
                        ...state.items[action.articleId as string],
                        {
                            loading: false,
                            type: action?.payload?.pub?.type as string,
                            ...action.payload,
                        },
                    ],
                },
            };
        case actionTypes.VERIFY_ITEM_REQUEST:
            return <ArticleState>{
                ...state,
                proofs: {
                    ...state.proofs,
                    [action.hash as string]: {
                        loading: true,
                    },
                },
            };
        case actionTypes.VERIFY_ITEM_SUCCESS:
            return <ArticleState>{
                ...state,
                proofs: {
                    ...state.proofs,
                    [action.hash as string]: {
                        loading: false,
                        verified: true,
                        qrCode: action.qrCode,
                        codeExp: action.codeExp,
                    },
                },
            };
        case actionTypes.SCAN_SUCCESS:
            return <ArticleState>{
                ...state,
                scan: action.payload,
            };
        case actionTypes.SCAN_GET_QR_SUCCESS:
            return <ArticleState>{
                ...state,
                scan: {
                    ...state.scan,
                    qrCode: action.qrCode,
                    codeExp: action.codeExp,
                },
            };
        case actionTypes.RESET_PROOFS:
            return <ArticleState>{
                ...state,
                proofs: {},
            };
        case actionTypes.RESET_SCAN:
            return <ArticleState>{
                ...state,
                scan: {},
            };
    }
    return state;
};

export default reducer;
