import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import notFoundImage from '../assets/images/Acoer-404-asset-v3.svg';
import newshashLogo from '../assets/images/newshash.svg';
import { Link } from 'react-router-dom';

function NotFoundView() {
    return (
        <div>
            <Grid container justify={'center'} style={{ marginTop: 30 }}>
                <Grid item>
                    <img src={newshashLogo} alt="NewHash Logo" style={{ width: '100%', marginBottom: 30 }} />
                </Grid>
            </Grid>
            <Grid container justify={'center'}>
                <Grid item md={4} xs={10}>
                    <img src={notFoundImage} alt="" />
                </Grid>
            </Grid>
            <Grid container justify={'center'}>
                <Grid item style={{ margin: 20 }}>
                    <Typography variant={'h6'}>Oops! Sorry, page not found.</Typography>
                </Grid>
            </Grid>
            <Grid container justify={'center'}>
                <Grid item>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <Button variant={'contained'} color={'primary'}>
                            Return to NewsHash
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}

export default NotFoundView;
