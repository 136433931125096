import React from 'react';
import Countdown from 'react-countdown';

// mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReplayIcon from '@material-ui/icons/Replay';

export interface ProofCountdownProps {
    tokenExpiration: string | undefined;
    countdownCount: number | undefined;
    handleVerify: () => void;
    allowRetry: boolean;
}

export interface CountdownProps {
    minutes: number;
    hours: number;
    seconds: number;
    completed: boolean;
}

const ProofCountdown: React.FC<ProofCountdownProps> = ({
    tokenExpiration,
    countdownCount = 0,
    handleVerify,
    allowRetry = true,
}) => {
    const countdownRenderer = ({ minutes, seconds, completed }: CountdownProps) => {
        if (completed) {
            return (
                <Grid container justify={'center'}>
                    <Grid item xs={allowRetry ? 9 : 11}>
                        <Typography variant={'caption'} color={'secondary'} style={{ textAlign: 'center' }}>
                            Proof expired as of {tokenExpiration}
                        </Typography>
                    </Grid>
                    {allowRetry && (
                        <Grid item xs={3}>
                            <Tooltip title={'Re-verify Asset'}>
                                <IconButton onClick={handleVerify}>
                                    <ReplayIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            );
        } else {
            return (
                <Grid container justify={'center'}>
                    <Grid>
                        <Typography variant={'caption'} color={'primary'} style={{ textAlign: 'center' }}>
                            Proof valid for the next {minutes} min {seconds} s
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
    };
    const expCount = countdownCount * 1000 - Math.floor(new Date().getTime());
    return (
        <Grid item style={{ marginBottom: 20 }}>
            {tokenExpiration && (
                <Countdown key={tokenExpiration} date={Date.now() + expCount} renderer={countdownRenderer} />
            )}
        </Grid>
    );
};

export default ProofCountdown;
