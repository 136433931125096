import React, { useEffect, useState } from 'react';
// import QrReader from 'react-weblineindia-qrcode-scanner';
import jwt_decode from 'jwt-decode';
import QrReader from 'react-qr-reader';

// redux
import { getItemDetails, resetScan } from '../components/Article/store/actionCreators';
import { connect } from 'react-redux';
import { ArticleAction, IItemDetailsProps } from '../components/Article/type';

// components and helpers
import newshashLogo from '../assets/images/newshash.svg';
import scanIcon from '../assets/images/qr-code-scanner-icon-1.jpg';

// mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProofCardDetails from '../components/Article/Sections/ProofCardDetails';
import ProofVerifyDetails from '../components/Article/Sections/ProofVerifyDetails';
import ProofCountdown from '../components/Article/Sections/ProofCountdown';
import TimerIcon from '@material-ui/icons/Timer';

export interface ScannerViewProps {
    getItemDetails: (scanner: boolean, id: string, articleId: string) => Promise<ArticleAction>;
    resetScan: () => Promise<ArticleAction>;
    scan: IItemDetailsProps;
}

const ScannerView: React.FC<ScannerViewProps> = ({ getItemDetails, resetScan, scan }) => {
    const [scanOpen, setScanOpen] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [countdown, setCountdown] = useState<number>(0);
    const [tokenExp, setTokenExp] = useState<string>('');
    const [expiredToken, setExpiredToken] = useState<boolean>(false);
    const noScan = Object.keys(scan).length === 0;
    const verifying = noScan && result && !expiredToken;

    useEffect(() => {
        const url = window.location.href;
        if (url.includes('jwt=')) {
            getDetails(url);
            window.history.pushState('', '', '/scan');
        }
    }, []);

    const handleScan = (data: any) => {
        if (data) {
            getDetails(data);
        }
    };

    const getDetails = (data: string) => {
        const token = data.split('=').pop();
        if (token) {
            let expCount = 0;
            let expirationTime = 0;
            const decodedData: IItemDetailsProps = jwt_decode(token);
            setResult(token);
            if (decodedData && decodedData.exp) {
                const tokenExp = new Date(decodedData?.exp * 1000);
                expCount = decodedData.exp;
                expirationTime = decodedData.exp * 1000 - Math.floor(new Date().getTime());
                setCountdown(expCount);
                setTokenExp(tokenExp.toString());
            }
            if (decodedData.objectId && (expirationTime > 1 || !decodedData.exp)) {
                getItemDetails(true, decodedData?.objectId, '');
            } else if (decodedData.objectId && expirationTime < 1) {
                setExpiredToken(true);
            }
        }
    };

    const handleError = (error: any) => {
        console.log('ERROR', error);
    };

    const handleReset = () => {
        setScanOpen(true);
        setExpiredToken(false);
        setResult('');
        setCountdown(0);
        setTokenExp('');
        resetScan();
    };

    return (
        <Grid container justify={'center'}>
            <Grid item style={{ margin: 30 }}>
                <Link to={'/'}>
                    <img src={newshashLogo} alt="NewHash Logo" />
                </Link>
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        width: '100%',
                        backgroundColor: !noScan ? 'rgb(200,230,200)' : 'rgba(66,104,174, 0.2)',
                        padding: '30px 0',
                    }}
                >
                    <Typography variant={'h4'} style={{ textAlign: 'center' }} color={'textSecondary'}>
                        {verifying
                            ? 'Verifying...'
                            : expiredToken
                            ? 'Stamp Expired'
                            : noScan
                            ? 'Verify Stamp'
                            : 'Success'}
                    </Typography>
                </div>
            </Grid>
            <Grid
                container
                alignItems="center"
                alignContent={'center'}
                justify="center"
                direction="column"
                style={{ minHeight: '50vh', maxWidth: '100vw' }}
            >
                {noScan && !result && (
                    <Grid item xs={12}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12}>
                                {scanOpen ? (
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <QrReader
                                            onError={handleError}
                                            onScan={handleScan}
                                            facingMode={'environment'}
                                            showViewFinder={true}
                                            style={{
                                                width: '100vw',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ marginTop: 50 }}>
                                        <img
                                            src={scanIcon}
                                            alt="Scanner"
                                            style={{
                                                width: 250,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                display: 'block',
                                            }}
                                            onClick={() => setScanOpen(true)}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                {!scanOpen && (
                                    <Typography variant={'subtitle1'} color={'primary'} style={{ textAlign: 'center' }}>
                                        Click on the image above to scan the QR code of your stamp for verification
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {verifying && !expiredToken && (
                    <Grid container justify={'center'}>
                        <Grid item xs={2}>
                            <CircularProgress />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant={'subtitle1'} color={'primary'} style={{ textAlign: 'center' }}>
                                We are working for you to ensure the trustness of this stamp...
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    {!noScan && result && !expiredToken && (
                        <Grid container justify={'center'}>
                            <Grid item xs={11} style={{ marginTop: 30 }}>
                                <ProofVerifyDetails
                                    qrCode={result}
                                    stampedOn={scan.created}
                                    transactionId={scan.transactionId}
                                    countdown={false}
                                    tokenExpiration={tokenExp}
                                    countdownCount={10000}
                                    handleVerify={() => console.log('verify')}
                                />
                            </Grid>
                            <Grid item xs={11} style={{ marginTop: 30 }}>
                                <ProofCardDetails proofData={scan} section={{}} />
                            </Grid>
                            <Grid item style={{ margin: '30px 0' }}>
                                <Button variant={'outlined'} color={'primary'} onClick={handleReset}>
                                    Verify Another Stamp
                                </Button>
                            </Grid>
                            <ProofCountdown
                                tokenExpiration={tokenExp}
                                countdownCount={countdown}
                                handleVerify={() => console.log('verify')}
                                allowRetry={false}
                            />
                        </Grid>
                    )}
                    {expiredToken && (
                        <Grid container justify={'center'}>
                            <Grid item>
                                <TimerIcon
                                    style={{ fontSize: 100, opacity: '0.8', marginTop: 30 }}
                                    color={'secondary'}
                                />
                            </Grid>
                            <Grid item xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'subtitle1'}>
                                    This stamp expired as of <i>{tokenExp}</i>.
                                </Typography>{' '}
                            </Grid>
                            <Grid item xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'subtitle1'}>Please re-verify asset and scan again.</Typography>{' '}
                            </Grid>
                            <Grid item style={{ margin: '30px 0' }}>
                                <Button variant={'outlined'} color={'primary'} onClick={handleReset}>
                                    Verify Another Stamp
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const mapStateToProps = (state: any) => {
    return {
        scan: state.scan,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItemDetails: (scanner: boolean, id: string, articleId: string) =>
            dispatch(getItemDetails(scanner, id, articleId)),
        resetScan: () => dispatch(resetScan()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannerView);
