import React from 'react';

// components and helpers
import newshashLogo from '../../assets/images/newshash.svg';
import forkastLogo from '../../assets/images/forkastLogo.png';
import acoerLogo from '../../assets/images/acoerLogo.png';

// mui
import Grid from '@material-ui/core/Grid';

const Footer: React.FC = ({}) => {
    return (
        <Grid container style={{ margin: '30px 0' }} justify={'space-between'} alignItems={'center'}>
            <Grid item xs={2}>
                <a href="https://forkast.news/" target={'_blank'} rel="noreferrer">
                    <img src={forkastLogo} alt="Forkast News" style={{ width: '100%' }} />
                </a>
            </Grid>
            <Grid item xs={3}>
                <a href="https://newshash.io/" target={'_blank'} rel="noreferrer">
                    <img src={newshashLogo} alt="NewsHash Logo" style={{ width: '100%' }} />
                </a>
            </Grid>
            <Grid item xs={2}>
                <a href="https://www.acoer.com/" target={'_blank'} rel="noreferrer">
                    <img src={acoerLogo} alt="Acoer Logo" style={{ width: '100%' }} />
                </a>
            </Grid>
        </Grid>
    );
};

export default Footer;
