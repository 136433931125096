import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import { HomeContext } from '../../../views/HomeView';
import { IArticle } from '../type';

export interface TitleSectionProps {
    section: IArticle;
}

const TitleSection: React.FC<TitleSectionProps> = ({ section = {} }) => {
    const { selected, handleChange, index } = useContext(HomeContext);
    const switchName = `switch-${index}`;

    return (
        <Grid container justify={'space-evenly'} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12} md={10} style={{ margin: '20px 0' }}>
                <Typography variant={'h4'} color={'inherit'} style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {section.title}
                </Typography>
            </Grid>
            <Grid item>
                <FormControlLabel
                    className={'proofSwitch'}
                    control={
                        <Tooltip title={'Toggle between read mode and prove mode'}>
                            <Switch
                                checked={selected?.[switchName] || false}
                                onChange={handleChange}
                                name={switchName}
                            />
                        </Tooltip>
                    }
                    label={
                        <Typography variant={'caption'} color={'textSecondary'}>
                            {selected?.[switchName] ? 'Prove Mode' : 'Read Mode'}
                        </Typography>
                    }
                />
            </Grid>
        </Grid>
    );
};

export default TitleSection;
