import React, { useEffect, useState, useContext } from 'react';
import fileDownload from 'js-file-download';
import axios from 'axios';

// redux
import { connect } from 'react-redux';
import { getItemDetails, resetProofs, verifyItem } from '../store/actionCreators';

// components and helpers
import { IArticleProps, IItemDetailsProps, ArticleAction, IProofDetails } from '../type';
import { getMarkdownText } from '../helpers/utils';
import { HomeContext } from '../../../views/HomeView';
import ProofCardDetails from './ProofCardDetails';
import ProofVerifyDetails from './ProofVerifyDetails';
import Notification from '../../Notifications/Notification';

// mui
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import { green } from '@material-ui/core/colors';

export interface ProofCardProps {
    title: string;
    section: IArticleProps;
    items: IItemDetailsProps[];
    proofs: IProofDetails[];
    getItemDetails: (scanner: boolean, id: string, articleId: string) => Promise<ArticleAction>;
    verifyItem: (articleId: string, objectId: string, hash: string) => Promise<ArticleAction>;
    resetProofs: () => Promise<ArticleAction>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '100%',
        },
        pos: {
            marginBottom: 12,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
            width: '100%',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        verifiedContainer: {
            backgroundColor: green[100],
        },
        verifiedContents: {
            padding: 20,
        },
    }),
);

const ProofCard: React.FC<ProofCardProps> = ({
    title,
    section = {},
    getItemDetails,
    verifyItem,
    resetProofs,
    items = [],
    proofs = [],
}) => {
    const classes = useStyles();
    const [cleanText, setCleanText] = useState<string>('');
    const [proofData, setProofData] = useState<IItemDetailsProps>();
    const [countdown, setCountdown] = useState<number>(0);
    const [tokenExp, setTokenExp] = useState<string>('');
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const { article } = useContext(HomeContext);

    useEffect(() => {
        return () => {
            resetProofs();
        };
    }, []);

    useEffect(() => {
        const articleItems: IItemDetailsProps = items[article?.id as string];
        if (articleItems instanceof Array && section.type && section.title) {
            const matchType = section.type;
            setProofData(articleItems.find((key) => key && key.type && key?.type?.toLowerCase() === matchType));
        }
    }, [items]);

    useEffect(() => {
        if (proofs[proofData?.hash as any]) {
            const data = proofs[proofData?.hash as any];
            if (data?.codeExp) {
                const expCount = data.codeExp;
                const tokenExp = new Date(data.codeExp * 1000);
                setCountdown(expCount);
                setTokenExp(tokenExp.toString());
            }
        }
    }, [proofs]);

    useEffect(() => {
        const getDetails = async () => {
            const queryItem = section.queryItem;
            if (article?.id) {
                await getItemDetails(false, `${article.id}/${queryItem}`, article.id);
            }
            if (section.markdown) {
                const articleText = await getMarkdownText(section.markdown);
                setCleanText(articleText.cleanText);
            }
            if (section.videoTranscript) {
                setCleanText(section.videoTranscript);
            }
        };

        if (article?.id) {
            getDetails();
        }
    }, []);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(cleanText)
            .then(() => {
                setOpenNotification(true);
            })
            .catch((err) => {
                console.log('Something went wrong', err);
            });
    };

    const handleDownload = () => {
        const textAssets = ['video-transcript', 'article'];
        if (section.queryItem && textAssets.includes(section.queryItem)) {
            let fullArticle = cleanText;
            if (section.authorSign) {
                fullArticle = cleanText + ' ' + section.authorSign;
            }
            fileDownload(fullArticle, `${title}.txt`);
        } else if (section.imagePath) {
            const path = `${window.location.href}${section.imagePath}`;
            axios
                .get(path, {
                    responseType: 'blob',
                })
                .then((res) => {
                    fileDownload(res.data, title);
                });
        }
    };

    const handleVerify = () => {
        const queryItem = section.queryItem;
        if (article && proofData && proofData.hash) {
            verifyItem(article?.id, `${article?.id}/${queryItem}`, proofData?.hash);
        }
    };

    return (
        <Grid container justify={'center'}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Grid container justify={'space-between'} alignItems={'center'} alignContent={'center'}>
                        <Grid item xs={6}>
                            <Typography variant={'h6'} gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {section.copy && (
                                <Tooltip title={'Copy Text'}>
                                    <IconButton onClick={handleCopy} disabled={!proofData?.hash}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {section.download && (
                                <Tooltip title={'Download'}>
                                    <IconButton onClick={handleDownload} disabled={!proofData?.hash}>
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <ProofCardDetails proofData={proofData} section={section} />
                    </Grid>
                </CardContent>
                <CardActions style={{ textAlign: 'center' }}>
                    {!proofs || !proofs[proofData?.hash as string] || proofs[proofData?.hash as string].loading ? (
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                    !proofData?.hash ||
                                    (proofs[proofData?.hash as string] && proofs[proofData?.hash as string].loading)
                                }
                                onClick={handleVerify}
                                style={{ width: '100%' }}
                            >
                                Verify
                            </Button>
                            {proofs[proofData?.hash as string] && proofs[proofData?.hash as string].loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    ) : (
                        <Grid container justify={'space-between'} className={classes.verifiedContainer}>
                            <Grid item className={classes.verifiedContents}>
                                <ProofVerifyDetails
                                    qrCode={proofs[proofData?.hash as any].qrCode}
                                    tokenExpiration={tokenExp}
                                    stampedOn={proofData?.created}
                                    countdown={true}
                                    transactionId={proofData?.transactionId}
                                    countdownCount={countdown}
                                    handleVerify={handleVerify}
                                />
                            </Grid>
                        </Grid>
                    )}
                </CardActions>
            </Card>
            <Notification
                open={openNotification}
                severity={'success'}
                message={'Text successfully copied to clipboard'}
                closeNotification={() => setOpenNotification(false)}
            />
        </Grid>
    );
};

export const mapStateToProps = (state: any) => {
    return {
        items: state.items,
        proofs: state.proofs,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItemDetails: (scanner: boolean, id: string, articleId: string) =>
            dispatch(getItemDetails(scanner, id, articleId)),
        resetProofs: () => dispatch(resetProofs()),
        verifyItem: (articleId: string, objectId: string, hash: string) =>
            dispatch(verifyItem(articleId, objectId, hash)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProofCard);
