import articleImage0 from '../../../assets/images/newsHashArticle.png';

export const articleBuild = [
    {
        id: 'articles/2021/01/29/blockchain-against-fake-news',
        title: 'How blockchain and DLT could combat misinformation and manipulation in the media',
        articleProps: [
            {
                type: 'video',
                title: 'Video',
                queryItem: 'video',
                readRender: true,
                width: 12,
                embed: true,
                url: 'https://www.youtube.com/embed/WKX5saSZc3s',
                download: false,
                copy: false,
                footerText: 'this is the footer text for a video',
            },
            {
                type: 'video transcript',
                title: 'Video Transcript',
                queryItem: 'video-transcript',
                readRender: false,
                width: 12,
                download: true,
                copy: true,
                videoTranscript:
                    "Angie Lau: [Last] year, Rappler CEO and fellow journalist Maria Ressa was charged and convicted in the Philippines for cyber libel. She's just one of countless examples of media under threat. Buried in the headlines of Covid-19, elections, the economy, trade wars — even Bitcoin — press freedom has been under attack, and to add insult to injury, media distrust has also been on the rise. In [last] year's annual poll by Gallup, six in ten Americans have expressed distrust in the media. Now, this survey has been conducted annually since 1972; back then, just around three in ten shared the same sentiment. So how did we get here and how do we fix this?\n" +
                    "Welcome to Word on the Block, the series that takes a deeper dive into blockchain and the emerging technologies that shape our world at the intersection of business, politics and economy. It's what we cover right here on Forkast.News. I'm Forkast.News Editor-in-Chief, Angie Lau. And today, this is a very special episode of Word on the Block because I'm not sure how many know this, but Forkast.News was actually founded on the feeling that technology could help restore trust in media — specifically blockchain. How potentially it could be a tool to protect truth and facts and hopefully restore faith in what journalists do.\n" +
                    "And there's an opportunity to start figuring that out right now. I want to welcome Jim Nasr; he's CEO and founder of Acoer, a blockchain application developer that has been using DLT and algorithms in the medical sector. And notably Acoer's HashLog is a health care tracker that uses Hedera Hashgraph's distributed ledger technology (DLT) to record and visualize medical data such as Covid developments. Now, this is a very special episode because this kicks off a pilot trial of NewsHash using blockchain as a tool to protect and authenticate original content in journalism and media. And it's going to do that right now in real time.\n" +
                    'Jim, welcome to the show.\n' +
                    '\n' +
                    "Jim Nasr: It's great to be here. Great to talk to you again.\n" +
                    '\n' +
                    'Lau: We are breaking new ground a little bit, and I want to share this story and how we got here. I remember talking to you earlier [last] year when Covid was just coming on the scene and Acoer was doing some really important work verifying and validating some of the Covid numbers that were coming out. And we all felt at the time that tracking numbers was going to be critical to battling Covid-19. And I think everybody was right about that. After the interview, do you remember, I mentioned to you that the applications really go beyond medical data. It also has a lot of relevance in journalism.\n' +
                    '\n' +
                    'Nasr: I remember that well, and if you can believe it, Angie, we made notes about that, myself and my partner in the business, we thought, "let\'s let\'s ruminate on this." And it turned out after a few months that actually we went back to it and realized that between advances we had already made into HashLog, particularly the data sampling service, as well as just communicating with some other folks in journalism and then back to yourself that there is an opportunity to explore and go — as we talked and speculated at that time — to go beyond the use case that we were playing with.\n' +
                    '\n' +
                    "Lau: That's absolutely true, and so here we are; blockchain, there is no doubt that with time stamping and verifying original sources, there's just so many possible applications. And there is no doubt that a lot of really smart people in blockchain and in media are working on this right now. NewsHash is just a little part of this bigger, wider story. What was really interesting about NewsHash was authenticating the actual content and ensuring that subsequently any reader could trust that this came from us. Tell us a little bit more about NewsHash, what it actually does, and we could talk about where we think it's going to go, but tell us first about NewsHash.\n" +
                    '\n' +
                    "Nasr: So, to everything there is some history, right? And I think with NewsHash the history was, as you mentioned, as we had really spent almost all of 2020 working on health data, we  evolved our thinking into this idea that we could basically show proof of data authenticity. That the data you're looking at in whatever context it is, whether it's public health reporting for Covid, whether it's medical endpoints from a digital asset, or rather a connected diagnostics app and things like this — which is the work we're doing at the Mayo Clinic and so on — that you could actually show the authenticity of the data.\n" +
                    "So then that got us thinking what if we could do the same thing in the context of, for instance, proof of authorship for an article or for a print image or for, as an example, this video that we're doing right now or snippets of it? There's a huge problem with misinformation, but also really manipulation of information. Whether it's in little snippets on social media, whether it's deep fakes, whether it is essentially plagiarism or taking written content and putting it in a different context. So all those things are real issues.\n" +
                    "Then, with the instance of Ressa and what is going on in the Philippines, and this real global problem just awakened us into this idea that the opportunity is there for us to extend something that we know does work. It works in production, it works fast, it works in an economically really feasible way for this use case. Because, for this use case, we know we're not experts at it. We can see, first of all, we're impacted by it ourselves, as readers, citizens in different contexts. But also we can see how it could really apply into this pretty globally applicable and relevant use case.\n" +
                    "So that was the history. We're practitioners, we poke around, we noodle with things. So that's really what we did. And within a few weeks, we had something that we could share with people like yourself and say, \"Look, this is what we think. We don't really know the domain, but we think working with somebody like you, we could make the domain. We could make it relevant to the domain.\"\n" +
                    '\n' +
                    "Lau: Absolutely. That's really powerful. I think one of the things that we all know to be true is that the trust in the media has eroded. Now, whether or not the stories and the quality, the journalism at the very top has eroded at all, certainly the structures around it, there is increasing necessity for people to elevate and improve on media literacy.\n" +
                    "But the technology and social media has sped up to a place where people still find it hard to catch up, and when you read something and there is a logo of The New York Times or CNN or BBC and it looks exactly like it, meanwhile the content is completely false. I can't tell you how many times that increasingly has happened to me personally and professionally over the past decade and increasingly so, that people say that this is my story when all they did was slap a logo on it or they slapped a face on it, and I don't know who or what is out there.\n" +
                    "What I found super intriguing about what you're doing at Acoer is that data from a medical point of view saves lives. False data could kill. And so the integrity of the data in the medical industry is of utmost importance. There cannot be even the smallest percentage of doubt when it comes to medical data,  or somebody gets killed. It's a matter of life and death. And I would say that journalism and trust and truth and facts are just as important, if not more.\n" +
                    '\n' +
                    "Nasr: Yeah. It's funny because, as in other parts of life, there are a number of real life scenarios that pop up almost daily. I'll give you an example, this is one that is very topical to all of us right now. So, thankfully the Pfizer vaccine is out now, and just over the last couple of days is being made available to U.S. citizens, it's been the case since last week in the U.K.\n" +
                    'So one of the things I have spoken to, you may remember from my CDC background, I\'ve spoken to various public health officials. And I know very important to them is this idea of "how do we really prove the supply chain information?" Never mind the actual flu, but how do we prove where the demand is and how do we prove that the supply is getting there and meeting the demand?\n' +
                    "Particularly since lives are at stake and particularly since this impacts all of us, and citizens like us, we expect accurate, timely reporting in all of this. So lo and behold, within a day of the vaccine being released, there's all these misinformation sites, blurbs and social media postings about alternatives, or essentially making the case the vaccine is available from alternative sources.\n" +
                    "We all know from working within this space, that's really not the case. However, there are all kinds of scammers already, so now you have this  combination of misinformation and medical information hand in glove together. That's the worst case scenario, very much leading to potentially not only losing your money and creating a difficult or bad experience, but also possibly leading to people dying because maybe they are going to inject themselves, inoculate themselves with drugs that are not the vaccine, or who knows what else is going on?\n" +
                    "So it's very topical, this scenario we're talking about is not going away. I'm a practical person, I think there's a lot people like us can do to talk about ways in which you can interject some level of these advanced technologies without necessarily having to revamp the entire industry.\n" +
                    '\n' +
                    "Lau: I think you're absolutely right. The supply chain of facts, the supply chain of truth, the supply chain of journalism, at the end of the day, this is critical content for the media consumer, for the reader, for the individual who's consuming that. We want the truth, the facts. People want truth. And it's hard to discern — especially with the technology, it's such a fast pace, especially social media — it's so hard to differentiate. And those media literacy skills need to be just spot on.\n" +
                    "Journalists have them, but we can't expect everybody to understand that if the font is off, the time stamp is off, that in context of this and there's some anonymity that still happens. NewsHash essentially stamps the content.\n" +
                    "For example, this video, this will be stamped by NewsHash. This stamp can essentially assure the viewer that what you're watching here was from Angie Lau and Jim Nasr. That even in the age of deep fakes — when you could put a face on anything, make that person say anything, it looks like the real person — even in that increasing age of that technology, this stamp can verify this video as real. That it's authenticated to me and you, this moment, this entire conversation in full context.\n" +
                    "I know it sounds super simple, but you can imagine the applications and how important that's going to be because we're already experiencing so much fake content out there that's demanding and trying to trick people into thinking that it's real. It's dangerous.\n" +
                    '\n' +
                    "Nasr: But what's interesting, Angie, is that it needs to be super simple. One of the big issues — and I'm really a software developer, a practitioner, not just in the context of blockchain, but but other kinds of technologies — is we're in a consumer world. So we need to be thinking about what does consumerism imply in this kind of a context? If consumers or even creators of content like us, if we have to significantly change our usability or our experience, most likely we're not going to do it. It's just not going to happen. So this is really, really important.\n" +
                    "These kinds of things, like data stamping, or in this case content stamping, really is happening behind the scenes. And the good news about blockchain is really its infrastructure; many people refer to it as a trust layer for the Internet. I think of blockchain as plumbing; imagine you're at home, you have the shower running, you've got the water coming at you, you've got the gauge and things like this. That's your experience. You're not worried about the plumbing underneath the floor and any of the tiling and where it's going to. It just works. It's supposed to work, every now and then you maintain it.\n" +
                    'But the experience is not one we constantly thinking "let me dig under the tiles here and figure out what the gauges are on the plumbing." That\'s the way we think about it. And technically speaking, we call it an abstraction, that\'s really the vision. The vision is: can we use this idea of distributed ledger technology, ideally in real time or near real time, to take a different piece of content or different health data items and things like this, and in an abstracted way — as an under layer, if you like — apply these stamping later on?\n' +
                    "If somebody says, \"that's really Angie's deep fake, that conversation didn't happen, or didn't happen at this time, or it was broken up, or here's the derivative\" or whatever, we can go and look at the data stamp on an immutable ledger. That ledger would bring us back with an encrypted reference to the actual source, and we can verify categorically — because there is a public immutable ledger — what is real, what is not real. So that's the idea.\n" +
                    "But the idea only works if you can make the technology work basically with almost no real impact to the user. If the user has to manage keys and has to use a whole different way of doing editorial's and things like this, it will never happen. So to me, this is always the trick, and that's why I love working with people like yourself, because as technologists, we don't necessarily know how you work and you don't necessarily know how the technology works. But if you marry the two domains, we can come up with a solution that provides you this proof of transaction, a proof of authorship. But also doesn't deviate the experience so it becomes a completely impractical tool.\n" +
                    '\n' +
                    "Lau: We're super excited to collaborate with you on this. I'll say it hands down. Technology is a tool in the hands of those who want to do better. And journalism is a critical pillar in how we should be functioning as a civilized society globally. I think we're increasingly seeing that. It's more important than ever before. And so to be able to speak truth and for it to be verified and authenticated and protect us against the erosion of doubt which is happening right now, it is a powerful restoration of an important pillar that props up the foundation of a healthy society, nothing less.\n" +
                    "And so it's powerful and I'm so thrilled that we're not the only ones who are working on this, that there is an opportunity at this moment to really apply technology in a critical and meaningful way. And this is our small part in it. So one day as we extrapolate where this could go, in the future I hope that all you see is that stamp, that QR code, that whatever it is that develops out of this, that the viewer will trust that this is the original source. Because right now, if you think about it, Jim, and everybody who's watching, how do you go back to your original source?\n" +
                    "First of all, you would have to go back to the original site, search on the original site. So, let's say this is a Forkast story, but you're questioning is it a Forkast story? I don't know. Did it come from Forkast? You go on our site and then you look for that, but then you'd have to search for it. And then you have to figure out cross-reference. But that critical point actually right now in Web 2.0, it's a 404 location URL. That is the only thing that distinguishes whether or not this is a fake story, or if it's a URL that originated from Forkast.News. That's a very weak line of defense. This absolutely strengthens the original sourcing.\n" +
                    '\n' +
                    "Nasr: It really is, and I think — I'm sure you come across this all the time as do we in implementing the solutions  — that technology is really way ahead of essentially traditional, conventional business processes, right? We talk about the deep fake, but whether it's machine learning and unsupervised deep learning and whether it's essentially geocoding technologies and proxies and VPNs, there's lots of different ways in which you can essentially, give one reality, but the real reality could be different. Like, your I.P. address could be one thing, whereas the reality is different, as an example.\n" +
                    "I think what we need to be aware that this is not going to go away. This is just accelerating. There's more and more sophisticated ways in which information if you want to manipulate it, can be manipulated and can be made to look like whatever it is that you want it to look like. So having some way of saying I can at least trace authenticity is hugely important. It's funny because if you and I had a conversation two years ago about this topic, that would not have been my thinking. I would have had other things ahead of this idea of a simple concept, relatively speaking, of proving authenticity.\n" +
                    'People talked about provenance and people talked about compliance and things like this, and so did I, to be honest, I\'m not excluding myself from this. And all that is good, but really, to me, those are like some some levels higher up, like derivatives of the very basic idea of "can you prove authenticity?" We\'re very much in this data land-grab world and much more so than ever as technologies consolidate like the four horsemen, the Amazon, Google, Apples and Facebook as they basically are dominating the Internet. You could argue and it would be right on point that we are all largely a product of those four or five companies, and we willingly contribute to them to become more of a product.\n' +
                    "Case in point, it's obscene that somebody like Bezos has the wealth he has because I don't think he has created that wealth for the rest of the world. Yet, that's the nature of the beast if you look at it for the other three or four companies. But that's a very scary situation when you think about the fact that these are essentially companies that act as states, and then have the  unilateral power to really do all kinds of things that they shouldn't do, but also none of us are going to be aware of.\n" +
                    "So not to digress into some conspiratorial line of thinking, that's not the intention, but as creators, as users, as citizens, there needs to be some way of telling, 'is this the real McCoy or is it not, and am I being manipulated? Or am I not? Or is it somewhere in between?\n" +
                    '\n' +
                    "Lau: You're totally right, because if you think about it in terms of tech, right now we actually trust it enough to filter out for us what is real or not real, if they abscond that responsibility, as consumers we're all left fending for ourselves. What this does is actually restore it down to the peer to peer level as a content creator.\n" +
                    "This is my work as a content consumer. You take a look at that. That is their work. There is no big tech, big brand in the middle that nods and approves your content. That increasingly is more important than ever. In the blockchain industry, we call it the intermediary. We're disintermediating trust, and reducing it back to peer to peer.\n" +
                    '\n' +
                    'Nasr: If you think about blockchain, we need to be students of history and just read things, as opposed to try and conjure them up. So you go back to the Satoshi Nakamoto White Paper from 2008, if you read it carefully enough, it comes to this idea of value creation attribution. "Somebody" is creating value in this bitcoin network and "somebody" is basically people with the GPUs who are doing the mining, they\'re racing to do is challenge and if they do it — they\'re expending the energy they\'re buying the GPU, they did the software, all of these things, where there is an inherent value to the rest of the network — then they deserve some a reward, a fee or whatever.\n' +
                    "That's really a great example of using game theory in a way that it promotes good behavior and it weeds out bad players. But ultimately, the whole thing is about somebody is creating value, and transparently, we're attributing or we're giving them a reward for their value that's created. That's a very different scenario then, than if you're going to Facebook, you have your whatever it is, your video, your picture, your ideas, your comments, whatever, that's not yours, and the value that's created is going to Facebook and to the advertisers. You're being subjected to value that you're creating for them. So, we have to change that.\n" +
                    "If you don't create any value, you're just a reader then your reward maybe is minimal or nothing because you're just a consumer of it. But I think this is one of those things where we have all these people creating videos and TikTok and all these other places and taking people's music and things like this, at the end of the day, you take that 1% on top who's very successful, [if] the other 99% would get something out of it, out of that value creation, that would be meaningful. That's not a negligible difference.\n" +
                    '\n' +
                    "Lau: You're right. It changes the business logic. And what's exciting is that this is just the beginning. And I hope that we lifted the hood a little bit to our viewers that this is simply the beginning of a conversation that involves all of us. Jim, it was a pleasure. I'm super excited that Forkast and Acoer are working on this together. And you'll see the story on Forkast.News. You'll see how we're applying NewsHash to this video, to that content. And it's great to have your technology expertise to share with journalists. And for our small part in what you're doing, we thank you.\n" +
                    '\n' +
                    "Nasr: Ditto Angie, certainly we feel likewise and we appreciate learning from you guys, but we think this kind of technology needs to be applied. We're not theoretical. We're practitioners. And what excites us is building stuff that can be used and it can be used economically, simply, in a way that doesn't change people's usability and experience. So this is a good example. And we love working with you guys. So thank you.\n" +
                    "Lau: Thank you. And thanks for joining us on Word on the Block, everyone for this special episode of Word on the Block I'm Angie Lau, Forkast.News, Editor-in-Chief. Until the next time.\n",
            },
            {
                type: 'article',
                title: 'Raw Article',
                queryItem: 'article',
                readRender: true,
                width: 12,
                markdown: 'article-0-1',
                download: true,
                copy: true,
                authorSign: 'Bryan Galvan, Forkast.News',
            },
            {
                type: 'illustration',
                title: 'Illustration 1',
                queryItem: 'illustration/1',
                readRender: false,
                width: 12,
                image: articleImage0,
                imagePath: 'images/newsHashArticle.png',
                download: true,
                copy: false,
            },
        ],
    },
];
