import * as actionTypes from './actionTypes';
import axios from 'axios';
import { IItemDetailsProps } from '../type';
import jwt_decode from 'jwt-decode';
import jwt from 'jsonwebtoken';
const API_ROOT = process.env.REACT_APP_API_ROOT;
const API_KEY = process.env.REACT_APP_API_KEY;
const ACCOUNT_SECRET = process.env.REACT_APP_ACCOUNT_SECRET;
const ACCOUNT_ID = process.env.REACT_APP_ACCOUNT_ID;

export const resetProofs = () => ({
    type: actionTypes.RESET_PROOFS,
});

export const resetScan = () => ({
    type: actionTypes.RESET_SCAN,
});

export const getItemDetails = (scanner: boolean, id: string, articleId: string) => async (
    dispatch: (arg0: { articleId: string; type: string; payload: any }) => void,
) => {
    let payload = {};
    dispatch({ type: actionTypes.ITEM_DETAIL_REQUEST, articleId, payload });
    return await axios
        .get<IItemDetailsProps>(`${API_ROOT}stamp`, {
            headers: {
                api_key: API_KEY,
            },
            params: {
                id,
            },
        })
        .then((response) => {
            if (response.data.jwt) {
                payload = jwt_decode(response?.data?.jwt);
                payload['created'] = response.data.created;
                payload['transactionId'] = response.data.transactionId;
                payload['objectId'] = response.data.objectId;
            }
            if (scanner) {
                dispatch({ type: actionTypes.SCAN_SUCCESS, articleId, payload });
                return payload;
            } else {
                dispatch({ type: actionTypes.ITEM_DETAIL_SUCCESS, articleId, payload });
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const verifyItem = (articleId: string, objectId: string, hash: string) => async (
    dispatch: (arg0: { qrCode: any; type: string; hash: string; codeExp: number }) => void,
) => {
    let qrCode;
    let codeExp = 0;
    dispatch({ type: actionTypes.VERIFY_ITEM_REQUEST, hash, qrCode, codeExp });
    const secret = 'm4yzFiLT$Z*#L*ddS&jn9ZUyCkg5zRZ$TdrFC95dxKDpCkvcEPRC1iNi&%lMnMot';
    const accountId = ACCOUNT_ID;
    const token = jwt.sign({ hash: hash }, secret, {
        algorithm: 'HS512',
        audience: 'https://stamp.hashlog.io/',
        issuer: accountId,
        subject: objectId,
        expiresIn: 602,
        notBefore: 0,
    });
    await axios
        .post(
            `https://stamp.hashlog.io/api/proof`,
            {
                jwt: token,
            },
            {
                params: {
                    prefix: 'https://newshash.io/scan?jwt=',
                },
                headers: {
                    api_key: 'PSctKAbxD2Y4xI1gYvrYt1@e2#3hAbwi',
                    accept: 'image/png',
                    'content-type': 'application/json',
                },
                responseType: 'blob',
            },
        )
        .then((response) => {
            qrCode = URL.createObjectURL(response.data);
            const decodedToken: IItemDetailsProps = jwt_decode(token);
            if (decodedToken.exp) {
                codeExp = decodedToken.exp;
            }
            dispatch({ type: actionTypes.VERIFY_ITEM_SUCCESS, hash, qrCode, codeExp });
        })
        .catch((error) => {
            console.log(error);
        });
};
