import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IArticleProps } from '../type';
import ResponsiveEmbed from 'react-responsive-embed';

export interface VideoSectionProps {
    section: IArticleProps;
}

const VideoSection: React.FC<VideoSectionProps> = ({ section = {} }) => {
    return (
        <Grid container justify={'center'}>
            <Grid item xs={11}>
                <ResponsiveEmbed src={section.url} ratio="16:9" />
            </Grid>
        </Grid>
    );
};

export default VideoSection;
