import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// redux
import { ArticleAction, ArticleState, DispatchType } from './components/Article/type';
import history from './components/Shared/history';
import reducer from './components/Article/store/reducer';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, Store } from 'redux';
import { Provider } from 'react-redux';

// styling
import './index.css';
import theme from './styles/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

// views
import ScannerView from './views/ScannerView';
import HomeView from './views/HomeView';
import NotFoundView from './views/NotFoundView';

const store: Store<ArticleState, ArticleAction> & {
    dispatch: DispatchType;
} = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <MuiThemeProvider theme={theme}>
                    <Switch>
                        <Route exact path="/" component={HomeView} />
                        <Route exact path="/scan" component={ScannerView} />
                        <Route component={NotFoundView} />
                    </Switch>
                </MuiThemeProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
